import React, { createContext, useState } from "react"

export const Context = createContext()

export const ContextProvider = ({ children }) => {
  const [category, setCategory] = useState("All")

  return (
    <Context.Provider
      value={{
        category,
        setCategory,
      }}
    >
      {children}
    </Context.Provider>
  )
}
